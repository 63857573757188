.sideMenu {
    display: flex;
    flex-direction: column;

    max-width: 20%;
    min-width: 15rem;
    font-weight: 300;
    box-shadow: 0px 0px 7px 0px #00000029;
    height: 91.8vh;
    position: relative;
}

.menuNav {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}
.menuNav a{
    color: #FFF !important;

}


.selectedItem{
    background-color: #009EBE !important;
}

.active {
    background-color: #009EBE;
}

.itemMenu {
    padding: 0.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: 0.8rem;
    font-weight: 400;
    color: #FFF;
    background-color: #84CFE6;
    text-decoration: none !important;
}

.itemMenu::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: #4EBDEF;
    -webkit-transition: .15s ease-in-out;
    -o-transition: .15s ease-in-out;
    transition: .15s ease-in-out;
}

.itemMenu a:hover {
    color: #FFF !important;
}

.itemMenu a {
    color: #FFF !important;
}

.menuTitle {
    display: flex;
    align-items: center;
    padding: 0.8rem 0.8rem 0.8rem 2.5rem;
    color: #21AAC8;
    letter-spacing: 1.14px;
    font-size: 0.9rem;
}

.itemMenu img{
    height: 1.2rem;
    width: 1.3rem;
    margin-right: .5rem;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}


.sideMain {
    width: 100%;
    font-weight: 300;
    height: 70vh;
    position: relative;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
}