.header {
    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 100%;
    /* height: 12vh; */
    padding: 0.2rem 0;

    background: url("../../assets/backgrounds/header-background.svg") no-repeat;
    background-size:     cover;                    
    background-repeat:   no-repeat;
    background-position: center center; 
}

.headerTitle {
    display: flex;
    justify-content: center;
    width: 100%;

    font-size: 3rem;
    color: #FFF;
    font-weight: 200;
}

.headerNav {
    position: absolute;
    right: 0;
    display: flex;
    
    justify-content: space-between;
    gap: 1.3rem;
}

.headerHome {
    display: flex;
    align-items: center;

    cursor: pointer;
}

.verticalLine {
    border-left: 2px solid #FFF;
}

.headerUser {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;

    margin-right: 2rem;
}

.headerUser img {
    border-radius: 50%;
    width: 3rem;
    border: 4px solid #FFF;
}

.headerUser span {
    font-weight: normal;
    font-size: 1rem;
}