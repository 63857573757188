.inputDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1.5rem;
    margin-top: 1%;
}

.attachmentsDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1rem;
}

.basicInfoDiv {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.basicInfoTitle {
    display: flex;
    justify-content: space-between;
}

.basicInfoTitle img {
    font-size: 1rem;
    color: #009ebe;
    width: 1rem;
}

.questionDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;

    margin-top: 1.5%;
}

.questionBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    font-size: 1rem;

    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;

    font-size: 0.9rem;
}
.formDiv {
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-top: 1%;
}

.imgDiv {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    column-gap: 0.8rem;
}

.imgDiv .img {
    width: 90%;
    height: 90%;
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
}

.imgContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 8rem;
    height: 8rem;
}
.imgContainer a {
    color: none !important;
    text-decoration: none !important;
    background-color: none !important;
}
.imgContainer .deleteImgIcon {
    position: absolute;
    top: 0;
    right: 0;
    transition: 400ms;
    opacity: 0;
    width: 1.2rem;
    z-index: 10;
    cursor: pointer;
    font-size: 1rem;
    color: #fe0c0c;
}

.imgContainer:hover .deleteImgIcon {
    opacity: 1;
    font-size: 1.5rem;
}

.imgContainer .img {
    display: block;
}
