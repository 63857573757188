.buttonSubmit{
  display: flex;
  justify-content: flex-end;
}
.disable {
  pointer-events: none;
  opacity: 0.75;
  display: flex;
  justify-content: flex-end;
}
.MainDiv{
  display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 90%;
    padding: 2rem;
}
.attachTextArea{
  width: 100%;
  padding: 3px;
  height: 7rem;
  resize: none;
}

