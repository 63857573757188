.container {
    position: relative;
    height: 100%;
    width: 100%;
}

.containerOpacity {
    /*  opacity: 0.5; */
}

.overlay {
    position: absolute;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    /* background-color: #eee; */
    width: 100%;
    height: 100%;
    z-index: 990;
}

.overlay .loaderWheel {
    position: absolute;
    top: 20rem;

    left: 0;
    right: 0;
    margin: 0 auto;

    opacity: 1;
    z-index: 999;
}

.loaderWheel {
    animation: spin 1s infinite linear;
    border: 2px solid rgb(215, 215, 215);
    border-left: 3.5px solid #00bcff;
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
