.title {
    display: flex;
    align-items: center;
    gap: 0.8rem;

    font-size: 1.5rem;
    font-weight: bold;

    padding-bottom: 0.25rem;

    color: #009EBE;
    border-bottom: 3px solid #009EBE;
}