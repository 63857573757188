.newMoc{
    display: flex;
    justify-content: flex-end;
}
.btnNewMoc{
    background: #009EBE 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    width: 9%;
    height: 2.5rem;
    font-weight: 300;
    font-size: 0.9rem;
}
.btnActionsMoc button{
    background: none;
    border: none;
}
.btnActionsMoc {
    display: flex;
}

.colorsIcons{
    color:#009EBE
}


.materialTable{
    margin-top: 1rem;
}


.materialTable input{
    box-shadow: unset !important;
}

.materialTable thead tr th{    
    position: 'sticky';
    top: 0;
    background: #009EBE !important;

}

.materialTable tbody tr{   
    height: 38px;

}
/*
.materialTable thead tr th:nth-child(11){   
    width:0% !important;
}

.materialTable thead tr th:nth-child(10){   
    width: 7% !important;
}

.materialTable tbody tr td:nth-child(4){    
    width: 7%;
}
.materialTable tbody tr td:nth-child(3){    
   width: 11%;
}

.materialTable tbody tr td:nth-child(5){    
    width: 8% !important;
 }
 
.materialTable tbody tr td:nth-child(6){    
    padding-bottom:0.9rem
}

.materialTable tbody tr td:nth-child(7){    
    width: 11%;
}

.materialTable tbody tr td:nth-child(8){    
    width: 8%;
}

.materialTable tbody tr td:nth-child(9){    
    width: 18%;
}
*/
.materialTable tbody tr td:nth-child(10){    
  position: relative;
  left: 5%;
}
.materialTable tbody tr td:nth-child(10) div{    
    display: flex;
    justify-content: flex-end;
}

.materialTable tbody tr td {
    padding: 0.5rem;
    border: none;
    font-size: 1rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: rgba(0, 0, 0, 0.54);
}
.materialTable tbody tr td:nth-child(11){    
    width: 0% !important;

}
.materialTable table{
    overflow-y: hidden;
    overflow-x: hidden;
}

::-webkit-scrollbar-track {
    background-color: #F4F4F4;
}
::-webkit-scrollbar {
    width: 10px;
    background: #F4F4F4;
}
::-webkit-scrollbar-thumb {
    background: #dad7d7;
}




@media (min-width: 1540px) and (max-width: 2000px) {
    .materialTable tbody tr td:nth-child(10){    
        width: 0% !important;
        position: relative;
        left: 4rem;
    }
}
@media (min-width: 2000px) and (max-width: 2700px) {
    .materialTable tbody tr td:nth-child(10){    
        width: 0% !important;
        position: relative;
        left: 3.5rem;
    }
}


@media (min-width: 2700px) {
    .materialTable tbody tr td:nth-child(10){    
        width: 0% !important;
        position: relative;
        left: 4rem;
    }
}
