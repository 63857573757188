* {
    margin: 0;
  }
  .loginBackground a{
    margin-bottom: 1rem;
    color: #46B8D3;
  }
  .App {
    font-family: sans-serif;
    text-align: center;
  }
  .loginBackground {
    display: flex;
  }
  .recaptcha{
    margin-left: 3rem;
  }
  .loginleft {
    background: #83CEE6;
    width: 50vw;
    height: 100vh;
    clip-path: polygon(0 0, 100% 0, 90% 83%, 0% 90%);
    display: flex;
    flex-direction: column;
    padding-top: 10vh;
    align-items: center;
  }
  .loginleft > h1 {
    color: white;
    margin-bottom: 3rem;
  }
  .loginleft > p {
    color: white;
    margin: 3px;
  }
  .loginleft > button {
    width: 150px;
    border: white solid 2px;
    padding: 10px 30px;
    background: transparent;
    border-radius: 20px;
    color: white;
    margin: 20px 0px;
    transition: 0.5s ease-in-out;
    font-weight: bolder;
    outline: none;
  }
  .loginleft > button:hover {
    background: white;
    color: #46B8D3;
    transition: 0.5s ease-in-out;
  }
  
  .loginright {
    background: white;
    width: 50vw;
    display: flex;
    flex-direction: column;
    width: 40vw;
    height: 80vh;
    align-items: center;
    margin-top: 3vh;
    padding-top: 20vh;
   
  }
  .loginright > h1 {
    margin: 10px;
  }
  .loginright div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loginright input {
    width: 20vw;
    border: none;
    background-color: lightgray;
    margin: 10px;
    padding: 10px 20px;
    border-radius: 20px;
    outline: none;
  }
  
  .loginright > button {
    border: none;
    padding: 12px 50px;
    background-color: #46B8D3;
    font-size: 15px;
    font-weight: bolder;
    color: white;
    border-radius: 30px;
    margin-top: 1rem;
    outline: none;
    margin-left: 3rem;
    transition: 0.5s ease-in-out;
  }

    
  .loginright > button:disabled{
    border: none;
    padding: 12px 50px;
    background-color: #95dced;
    font-size: 15px;
    font-weight: bolder;
    color: white;
    border-radius: 30px;
    margin-top: 1rem;
    outline: none;
    transition: 0.5s ease-in-out;
    margin-left: 3rem;
  }

  .loginright div >svg {
    position: relative;
    right: 14%;
    cursor: pointer;
  
  }
  .loginright div {
    display: flex;
    align-items: center;
  
  }
  .loginright > button:hover {
    box-shadow: 0px 0px 5px 1px black;
    transition: 0.5s ease-in-out;
  }
  .loginright > button::after {
    background: black;
    transition: 2s ease-in-out;
  }
  .loginright > button:active:after {
    transition: 0s;
  }
  .loginright > a:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transition: 0.5s ease-in-out;
  }
  .loginright > a::after {
    background: black;
    transition: 2s ease-in-out;
  }
  .loginright > a:active:after {
    transition: 0s;
  }

  .loginright > p {
    margin: 10px;
    font-weight: bold;
  }
  .loginright img {
    margin: 0px 10px;
  }
  
  @media (max-width: 480px) {
  
    .loginleft {
      display: none;
    }
  
    .loginright {
      width: 100vw;
      margin-top: 0px;
      padding-top: 30px;
      background-color: #46B8D3;
      clip-path: polygon(0 0, 100% 0, 90% 83%, 0% 90%);
    }
    .loginright > input {
      width: 50vw;
      background: white;
    }
    .loginright > button {
      background: white;
      color: #46B8D3;
    }
  }
  