.basicInfoDiv {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.basicInfoTitle {
    display: flex;
    justify-content: space-between;
}

.basicInfoTitle img {
    font-size: 1rem;
    color: #009ebe;
    width: 1rem;
}

.checklistDiv {
    box-shadow: 0px 0px 6px #00000029;
    padding: 1rem;
}

.questionDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;

    margin-top: 1.5%;
}

.questionBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;

    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;

    font-size: 0.9rem;
}

.stepGroup {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    border-bottom: 2px solid #009ebe;
    padding-bottom: 1%;
}
