.basicInfoDiv {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.basicInfoTitle {
    display: flex;
    justify-content: space-between;
}

.basicInfoTitle img {
    font-size: 1rem;
    color: #009ebe;
    width: 1rem;
}

.disable {
    pointer-events: none;
    opacity: 0.75;
}

.checklistDiv {
    box-shadow: 0px 0px 6px #00000029;
    padding: 1rem;
}

.questionDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;

    margin-top: 1.5%;
}

.questionBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 2rem;

    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;

    font-size: 0.9rem;
}

.makeshiftRadioButton {
    width: 1rem;
    height: 1rem;

    border-radius: 50%;
    border: 1px gray;
}

.makeshiftCheckedRadioButton {
    width: 1rem;
    height: 1rem;

    border-radius: 50%;
    border: 1px blue;
}

.hideDiv {
    height: 0;
    overflow: hidden;
}

.observation {
    color: red;
    font-size: 1rem;
}