.uploadDiv {
    font-size: 0.9rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
}

.uploadInput {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    padding: 0.2rem 0.25rem;
}

.uploadDiv span {
    color: #B2B0B0;
}

.imgDiv {
    margin-top: 1%;
    display: grid;
    grid-template-columns: repeat(15, 1fr);
    column-gap: 0.8rem
}

.imgDiv .img {
    width: 90%;
    height: 90%;
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
}

.imgContainer { 
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative; 
    width: 6rem;
    height: 6rem;
}

.imgContainer .deleteImgIcon { 
    position: absolute; 
    top:0; 
    right:0; 
    transition: 400ms;
    opacity: 0;
    width: 1.2rem;
    z-index: 9999;
    cursor: pointer;
    font-size: 1rem;
    color: #FF4141;
}

.imgContainer:hover .deleteImgIcon {
    opacity: 1;
    font-size: 1.5rem;
}

.imgContainer .img { 
    display: block; 
}


.attachmentsDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    gap: 1rem;
    margin-top: 1rem;
}

.imgHeader {
    width: 100%;
    background-color: #009EBE;
    color: #FFF;
    padding: 0.5rem;
    font-weight: 300;
    font-size: 0.85rem;
}

.imgDiv {
    
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    column-gap: 0.8rem
}

.imgDiv .img {
    width: 90%;
    height: 90%;
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
}

.imgContainer { 
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative; 
    width: 8rem;
    height: 8rem;
}
.imgContainer a {
    color: none !important;
    text-decoration: none !important;
    background-color: none !important;
}
