.button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 1.8rem 0.3rem 1.8rem;
    background-color: #009EBE;
    color: #FFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    font-size: 1.15rem;
    font-weight: 300;
}