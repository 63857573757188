.containerDiv {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.chartsDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
}

.chartContainer {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;
    padding: 0.5rem 1rem;
    height: 30rem;
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.secondContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-top: 2%;
}
.chartContainer input::placeholder {
    font: 1.25rem;
}

@media (max-width: 1280px) {
    .chartContainer {
        height: 50rem;   
    }
}
