.stepsDiv {
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;

    margin-top: 1.75%;
    
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    padding: 1.8rem 1.4rem;
    font-size: 0.8rem;
}

.step {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.step span {
    font-size: 0.8rem;
    text-align: center;
}

.stepIcon {
    width: 5rem;
    height: 5rem;

    display: flex;
    align-items: center;
    justify-content: center;
}

.stepIcon img {
    width: 100%;
    height: 100%;
}

.pastStep {
    background-color: #009EBE;
    opacity: 0.65;
}

.currentStep {
    background-color: #009EBE;
}

.currentStepTitle {
    font-weight: bold;
}