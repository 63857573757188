@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;700&display=swap");

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
b,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-family: "Poppins", sans-serif;

    line-height: normal;
    text-decoration: none;
}

body {
    color: #fff;
}

.PDFExport {
    width: 100%;
    /* aspect-ratio: 1/1.444; */
}

.exportPdfDiv {
    display: flex;
    justify-content: flex-end;

    margin-top: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

h1 {
    font-size: 2em;
}

h2 {
    font-size: 1.5em;
}

h3 {
    font-size: 1.3em;
}

h4 {
    font-size: 1em;
}

h5 {
    font-size: 0.8em;
}

h6 {
    font-size: 0.7em;
}

/* a:focus, a:hover {
	color: #FFF !important;
} */

.mainDiv {
    max-width: 96.5%;
    min-width: 15rem;
    padding: 2rem 2.5rem 2rem 2.5rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 4px;

    margin: 0 auto;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    margin-left: 2rem;
    margin-right: 2rem;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    color: #7c7c7c;
    width: 30vw;
}

.inputContainer input {
    width: 100%;
    border: none;
    height: 2.3rem;
    outline: 0;
    padding-left: 5px;
    font-size: 1rem;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 3px;
}

.inputEdit {
    width: 100%;
    border: 1px solid #00000029;
    outline: 0;
    padding-left: 5px;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 3px;
}

.buttonFlex {
    display: flex;
    justify-content: flex-end;
}

textarea {
    width: 100%;
    border: none;
    outline: 0;
    box-shadow: 0px 0px 6px #00000029;
    border-radius: 3px;
    padding-left: 5px;
    font-size: 1rem !important;
}

label {
    vertical-align: middle;
}

table {
    border-spacing: 0 0.55rem;
    border-collapse: separate;
    font-size: 0.85rem;
    width: 100%;
    table-layout: auto !important;
}

.inputError {
    color: #eb4665;
    font-weight: 400;
    font-size: 0.8rem;
    margin-top: 0.2rem;
}

.displayNone {
    display: none;
}

.iconTableDiv {
    display: flex;
    height: 1.2rem;
    font-size: 0.8rem;
    align-items: center;
    justify-content: center;
    gap: 0.2rem;
    color: #009ebe;
}

.flex {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

/* Swal */

.swal2-popup.swal2-toast .swal2-title {
    margin: 0 !important;
}

/* MODAL */

.headerModal {
    height: 3rem;
    background-color: #009ebe;
    padding: 0.5rem;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.headerModal h2 {
    margin-top: 0.6rem;
    font-size: 2.2rem;
    cursor: pointer;
}
.rs-picker-default .rs-picker-toggle.rs-btn {
    padding-top: 2px !important;
}
.rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
    top: 2px !important;;
}

@media (max-width: 1280px) {
    .rs-picker-default .rs-picker-toggle.rs-btn {
        padding-top: 2px !important;
    }
    .rs-picker-default .rs-picker-toggle.rs-btn .rs-picker-toggle-caret, .rs-picker-daterange .rs-picker-toggle.rs-btn .rs-picker-toggle-caret {
        top: 2px !important;;
    }
    
}


.modalTableOverflowDiv {
    max-height: 35%;

    margin-top: 0.5rem;
    padding: 0 2rem;
    padding-right: 2rem;

    margin-bottom: 1rem;

    overflow-y: auto;
}

.modalContainer {
    position: relative;

    display: flex;
    flex-direction: column;

    height: calc(100% - 3rem);
}

.modalFirstDiv {
    padding: 2rem 2rem 0 2rem;
}

.modalFooter {
    padding: 0 2rem 2rem 2rem;

    margin-top: auto;

    display: flex;
    justify-content: flex-end;
}

.rs-picker-toggle {
    height: 2.5rem;
    font-size: 1rem;
}

.rs-picker-toggle-value {
    font-size: 1rem;
}

.rs-checkbox-checker {
    font-size: 1rem;
}

.rs-picker-select-menu-items {
    margin-bottom: 6px;
    overflow-y: auto;
    font-size: 1rem;
}

.rs-auto-complete input {
    font-size: 1rem;
}

a:hover {
    color: #009ebe;
    text-decoration: none;
    opacity: 1;
}

.headerModal h3 {
    font-size: 1.2rem;
}

@media (max-width: 1900px) {
    html {
        font-size: 12.5px;
    }
}

@media (max-width: 1366px) {
    html {
        font-size: 11px;
    }
}

@media (max-width: 1280px) {
    html {
        font-size: 9px;
    }
}

@media (max-width: 1024px) {
    html {
        font-size: 7px;
    }
}

@media (max-width: 800px) {
    html {
        font-size: 5px;
    }
}
@media print {
    html, body {
      font-size: 70%; /* Fixed invoice print page content issue */
    }
  }