.addButton {
  box-shadow: 0px 0px 6px #00000029;
  border-radius: 4px;
  font-size: 1rem;
  color: #009EBE;
  padding: 0.7rem 0.7rem 0.3rem 0.7rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  background: white;
}
.mainContainer{
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}