.inputDiv {
    display: flex;
    justify-content: space-between;
    gap: 1.5rem;
    margin-top: 1%;
}

.attachmentsDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    gap: 1rem;
}

/* Checklist */

.basicInfoDiv {
    box-shadow: 0px 0px 6px #00000029;
	border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.basicInfoTitle {
    display: flex;
    justify-content: space-between;

}

.basicInfoTitle img {
    font-size: 1rem;
    color: #009EBE;
    width: 1rem;
}

.questionDiv {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.8rem;

    margin-top: 1.5%;

    box-shadow: 0px 0px 6px #00000029;
	border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.questionBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    font-size: 1rem;

    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
    box-shadow: 0px 0px 6px #00000029;
	border-radius: 4px;
}

.imgDiv {
    
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    column-gap: 0.8rem
}

.imgDiv .img {
    width: 90%;
    height: 90%;
    box-shadow: 0px 0px 6px #00000049;
    object-fit: cover;
}

.imgContainer { 
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative; 
    width: 8rem;
    height: 8rem;
}

.imgHeader {
    width: 100%;
    background-color: #009EBE;
    color: #FFF;
    padding: 0.5rem;
    font-weight: 300;
    font-size: 0.85rem;
}

