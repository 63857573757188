.basicInfoDiv {
    box-shadow: 0px 0px 6px #00000029;
	border-radius: 4px;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.basicInfoTitle {
    display: flex;
    justify-content: space-between;

}

.basicInfoTitle img {
    font-size: 1rem;
    color: #009EBE;
    width: 1rem;
}

.observation {
    color: red;
    font-size: 1rem;
}