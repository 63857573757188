.buttonPlus {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
    padding: 0.3rem 1.5rem 0.3rem 1rem;
    background-color: #009EBE;
    color: #FFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    font-size: 1rem;
    font-weight: 300;
}