.rc-table tbody tr,.MuiTable-root tbody tr, .rc-table-footer {
    box-shadow: 0px 0px 6px 0px #ccc;
    border-radius: 5px;
    font-weight: 400;
    color: #7C7C7C;
}

.rc-table tbody tr td, .MuiTable-root tbody tr td{
    padding: 0.5rem;
}


table {
    border-spacing: 0 0.5rem !important;
    border-collapse: separate !important;
}


.rc-table thead tr, .MuiTable-root thead tr{
    background: #009EBE;
    color: white;
    border-radius: 5px;
}

.rc-table .rc-table-thead {
    border-radius: 5px;
    font-weight: 200;
}

.rc-table thead tr th, .MuiTable-root thead tr th{
    padding: 0.5rem;
    font-weight: 300;
}

    

.rc-table-header, .MuiTableHead-root{
    margin-bottom: -1rem;
}

.MuiTable-root input{
    box-shadow: none !important;
}
/* 
.MuiTableBody-root tbody tr:first-child{
    display: none;
} */

/* .MuiToolbar-regular{
    visibility: hidden;
} */

.MuiTable-root{
    border-spacing: 0 0.5rem !important;
    border-collapse: separate !important;
    padding: 0.1rem !important;
}

.MuiPaper-elevation2 {
    box-shadow: none !important;
}

.containerTableHover tbody tr{
    cursor: pointer;
}

.MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: transparent !important
}