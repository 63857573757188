.pageContainer {
    height: 100vh;
    width: 100vw;
    background-image: url(../../assets/backgrounds/home-background.svg);
    background-size: cover;
    color: #FFF;
    overflow-x: hidden;

    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.header {
    display: flex;
    gap: 3rem;
    align-items: center;
    height: 12vh;
}

.logoutButton {
    background: #fff;
    color: #2EAAC4;
    border-radius: 6px;
    height: 36px;
    display: flex;
    border: none;
    justify-content: center;
    align-items: center;
}

.logout{
    display: flex;
    flex-direction: column;
    width: 30%;
    height: 32px;
    align-items: center;
}

.logo {
    width: 24rem;
    display: flex;
    justify-content: flex-start;
}

.logo img {
    width: 24rem;
    display: block;
}

.headerNav {
    display: flex;
    justify-content: space-between;
    color: #FFF;
    font-size: 1.1rem;
    padding: 0 2rem;
    width: 100%;
}

.headerNavBlock {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    width: 60%;
    height: 3rem;
    font-size: 0.9rem;
}

.headerNavOption {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 0 1rem;
    width: 100%;
    height: 1.5rem;
    border-right: 2px solid #FFF;
}

.numberCircle {
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid #F07D00;
    border-radius: 50%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.optionText {
    cursor: pointer;
    transition: transform .1s;
}

.optionText:hover {
    transform: scale(1.02);
    font-weight: bold;
}

.headerNavOptionLast {
    border: none;
}

.headerContent {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 2.5rem;
}

.headerUser {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    align-items: center;
    margin-right: 1%;
    font-size: 1rem;
    width: 20%;
}

.headerUser img {
    border-radius: 50%;
    width: 3rem;
    border: 3px solid #FFF;
    object-fit: cover;
}

.headerUser span {
    font-weight: bold;
}

.mainTitle {
    margin-top: 8%;
    margin-left: 8%;
    font-size: 4.5rem;
}

.mainButtons {
    margin-top: 15%;
    margin-left: 8%;
    display: flex;
    justify-content: flex-start;
    gap: 3.5rem;
}

.mainButton {
    width: 13rem;
    display: flex;
    align-items: center;
    padding: 0.4rem 2rem 0.4rem 0.5rem;
    gap: 1rem;
    box-shadow: 0px 0px 6px #00000029;

    background-color: #FFF;
    color: #009EBE;
    border-radius: 57px;

    font-size: 1rem;

    cursor: pointer;
}

.mainButton img {
    width: 3.2rem;
}
