.navPagesContainer {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid #009EBE;
    padding: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
    margin-top: 1.5rem;
}

.navPages{
    color: #009EBE;
    
    display: flex;
    align-items: center;

}

.navPages img{
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    
}

.navPages h4{
    cursor: pointer;
    text-decoration: none;
}

.navPages h3 {
    margin-left: 0.5rem;
    margin-right:0.5rem;
    font-size: 1.8rem;
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    padding: 0.3rem 1.8rem 0.3rem 1.8rem;
    background-color: #009EBE;
    color: #FFF;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 4px;
    font-size: 1.15rem;
    font-weight: 300;
}